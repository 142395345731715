import React, { useState, useEffect } from 'react'
import setting from '../../util/settings';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { ROLES } from "../../constants";
import actions from '../../redux/actions/index';

// Pruvo1: 612923f26ba97d03a67348d4
// Pruvo2: 612928956ba97d03a67348e8
// Pruvo5: 6129290f6ba97d03a67348f4

const GDP = (props) => {

    const gdpWidth = 70    // Porcentaje a reducir el ancho
    const originalWidth = 1000
    const originalHeigth = 600
    const windowWidth = Math.ceil(window.innerWidth * gdpWidth / 100)
    const windowHeight = Math.ceil(originalHeigth * windowWidth / originalWidth)

    //var params = { "ds1.userId": "612928956ba97d03a67348e8" }
    var params = { 
        "userIdAll": props.userId,
        "sessionIdAll": Math.floor(Math.random() * 10000).toString(),
        "userIdEvent": props.userId,
        "sessionIdEvent": Math.floor(Math.random() * 10000).toString() }
    var paramsAsString = JSON.stringify(params);
    var encodedParams = encodeURIComponent(paramsAsString)
    const https = setting.dataStudioGDP
    //const https = "https://datastudio.google.com/embed/reporting/cbaa29ce-2709-459a-8ae8-6d4c733a8d15/page/p_c68f2xz8tc"
    const httpsDataStudio = https + "?params=" + encodedParams
    console.log("LINK: ", httpsDataStudio)
    
    return (
        <Container style={{ marginTop: "-15px" }}>
            <iframe allowfullscreen="1" width={windowWidth} height={windowHeight} id="GDP"
                src={httpsDataStudio} frameborder="0" style={{ border: 0 }}>
            </iframe>
        </Container>
    )
}

const mapStateToProps = (state) => ({
    userId: state.user.id,
    reduxState: state,
    establishmentId: state.user.selectedEstablishment !== undefined ? state.user.selectedEstablishment._id : ''
});

export default withTranslation('common')(connect(mapStateToProps)(GDP));